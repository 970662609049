import { FC, Suspense, lazy } from 'react';
import SuspenseLoader from './components/SuspenseLoader';

const Loader = (Component: FC<any>) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication
const Login = Loader(
  lazy(() => import('src/pages/Authentication/Login/Login'))
);

// Account Setup
const AccountSetup = Loader(lazy(() => import('src/pages/AccountSetup')));

// Dashboards
const Dashboard = Loader(lazy(() => import('src/pages/Dashboard')));

// Tours
const Tours = Loader(lazy(() => import('src/pages/Tours')));
const TourForm = Loader(lazy(() => import('src/pages/Tours/TourForm')));

// Hotels
const Hotels = Loader(lazy(() => import('src/pages/Hotels')));
const HotelForm = Loader(lazy(() => import('src/pages/Hotels/HotelsForm')));

// Car Hire
const CarHire = Loader(lazy(() => import('src/pages/CarHire')));
const CarHireForm = Loader(lazy(() => import('src/pages/CarHire/CarHireForm')));

// inquiries
const Inquiries = Loader(lazy(() => import('src/pages/Inquiry')));

// Admins
const Admins = Loader(lazy(() => import('src/pages/Organisation/Admins')));



// Error Pages
const Status404 = Loader(lazy(() => import('src/pages/Error/Status404')));

export const RouterLazyLoader = {
  Login,
  Dashboard,
  Status404,
  Inquiries,
  Admins,
  AccountSetup,
  Tours,
  Hotels,
  CarHire,
  TourForm,
  HotelForm,
  CarHireForm
};
