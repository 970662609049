import React from 'react';

import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import GuestGuard from './guards/GuestGuard';
import AuthGuard from './guards/AuthGuard';
import { RouterLazyLoader } from './router-loader';

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/login" replace={true} />
      },
      {
        path: 'login',
        element: (
          <GuestGuard>
            <RouterLazyLoader.Login />
          </GuestGuard>
        )
      },
      {
        path: 'account-setup',
        element: (
          <GuestGuard>
            <RouterLazyLoader.AccountSetup />
          </GuestGuard>
        )
      },
      {
        path: '*',
        element: (
          <GuestGuard>
            <RouterLazyLoader.Status404 />
          </GuestGuard>
        )
      }
    ]
  },
  {
    path: 'app',
    element: (
      <AuthGuard>
        <SidebarLayout />,
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/app/dashboard" replace={true} />
      },
      {
        path: 'dashboard',
        element: <RouterLazyLoader.Dashboard />
      },
      {
        path: 'tours',
        element: <RouterLazyLoader.Tours />
      },
      {
        path: 'tours/create',
        element: <RouterLazyLoader.TourForm />
      },
      {
        path: 'tours/create/:id',
        element: <RouterLazyLoader.TourForm />
      },
      {
        path: 'hotels',
        element: <RouterLazyLoader.Hotels />
      },
      {
        path: 'hotels/create',
        element: <RouterLazyLoader.HotelForm />
      },
      {
        path: 'hotels/create/:id',
        element: <RouterLazyLoader.HotelForm />
      },
      {
        path: 'car-rentals',
        element: <RouterLazyLoader.CarHire />
      },
      {
        path: 'car-rentals/create',
        element: <RouterLazyLoader.CarHireForm />
      },
      {
        path: 'car-rentals/create/:id',
        element: <RouterLazyLoader.CarHireForm />
      },
      {
        path: 'admins',
        element: <RouterLazyLoader.Admins />
      },
      {
        path: 'inquiries',
        element: <RouterLazyLoader.Inquiries />
      }
    ]
  }
];

export default routes;
