import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InboxIcon from '@mui/icons-material/Inbox';
import TourIcon from '@mui/icons-material/Tour';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';

export const MenuIcons = {
    Dashboard: DashboardOutlinedIcon,
    Form: AssignmentIcon,
    Message: InboxIcon,
    Users: PeopleOutlineOutlinedIcon,
    Settings: SettingsOutlinedIcon,
    FAQ: LiveHelpIcon,
    Admins: AdminPanelSettingsIcon,
    Tours: TourIcon,
    Hotels: BedroomParentIcon,
    CarHire: AirportShuttleIcon,
}