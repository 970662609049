import { MenuIcons } from './MenuIcons';

export interface IMenuItem {
  link: string;
  icon: any;
  title: string;
  isVisible?: (user: any) => boolean;
}

const DashboardMenuItems: IMenuItem[] = [
  {
    link: '/app/dashboard',
    title: 'Dashboard',
    icon: MenuIcons.Dashboard
  },
  {
    link: '/app/inquiries',
    title: 'Inquiries',
    icon: MenuIcons.Message
  },
  {
    link: '/app/tours',
    title: 'Tours',
    icon: MenuIcons.Tours
  },
  {
    link: '/app/hotels',
    title: 'Hotels',
    icon: MenuIcons.Hotels
  },
  {
    link: '/app/car-rentals',
    title: 'Car Hire',
    icon: MenuIcons.CarHire
  }
];

const OrganisationMenuItems: IMenuItem[] = [
  {
    link: '/app/admins',
    title: 'Admins',
    icon: MenuIcons.Admins
  },
  {
    link: '/app/settings',
    title: 'Settings',
    icon: MenuIcons.Settings
  }
];

export { DashboardMenuItems, OrganisationMenuItems };
